import HttpInterceptorService from './http-interceptor.service';

class AuthService extends HttpInterceptorService {

    async login(payload) {
        const response = await this._httpClient.post("users/login", payload);
        const user = response?.data;

        if (user?.access_token) {
            this.setCurrentUser(user);

            // check if there is any route which the user was trying to access
            const redirectUrl = localStorage.getItem('redirectUrl') || "/home";
            localStorage.removeItem('redirectUrl');

            window.location.href = redirectUrl;
        }
        return user;
    }

    async logout() {
        try {
            // to clear the cookies
            await this._httpClient.post("users/logout");
        }
        catch (err) {
            console.error(err);
            return;
        } finally {
            localStorage.removeItem("user");
        }
    }

    register(payload) {
        return this._httpClient.post("users/register", payload);
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    checkTokenExpired(token){
        const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))
        if(jwtPayload && jwtPayload.exp){
            return Date.now() >= jwtPayload.exp * 1000;
        }
        return false;
    }
    
    isLoggedIn() {
        const user = this.getCurrentUser();
        if (user && user !== '') {
            const token = user?.access_token;
            if (token || token !== '')
                return !(this.checkTokenExpired(token));
        }
        return false;
    }

    authGuard() {
        const isLoggedIn = this.isLoggedIn();

        const currentPathName = (window.location.pathname).toLowerCase();

        // Define the root path and exceptional paths(where the user can go without logging in)
        const rootPath = '/';
        const exceptionalPaths = ['/register', '/joinus', '/passwordreset', '/passwordreset/verify'];

        if (!isLoggedIn && currentPathName !== rootPath) {

            if (exceptionalPaths.includes(currentPathName)) {
                return true;
            } else {
                // store the route in localstorage which will be used to re-direct the user
                localStorage.setItem('redirectUrl', window.location.pathname);
                window.location.href = rootPath;
                return null;
            }
        } else if (isLoggedIn && currentPathName === rootPath) {
            window.location.href = '/home';
            return null;
        } else {
            return true;
        }
    }

    //TODO Get user from app state
    setCurrentUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
    }
}

const authServiceInstance = new AuthService();

export default authServiceInstance;