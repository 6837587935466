import { Button, Upload } from "antd";
import UploadIcon from "../../assets/images/icons/UploadIcon";

import ApiService from "../../api";
import utils from "../../utils";

import styles from "./index.module.css";
import NotificationService from "../../services/notification.service";

const { Dragger } = Upload;

export default function UploadDragger({ allowMultiple, showUploadList, customRequest, folderName, setFiles, ...props }) {

    const handleFileUpload = (file) => {

        if (!folderName) {
            NotificationService.error("The files cannot be uploaded at the moment. \nPlease try again later!");
            return;
        }

        const reader = new FileReader();

        reader.onload = function (event) {
            const base64 = event.target.result;
            const fileData = {
                data: base64,
                fileName: utils.sanitizeFileName(file?.file?.name),
                folderName: folderName,
                containerName: JSON.parse(localStorage?.getItem("user"))?.agent_id,
            }

            ApiService.uploadDocs(fileData).then((response) => {
                if (response?.error)
                    return;

                setFiles(prevFiles => [
                    ...prevFiles,
                    {
                        name: fileData.fileName,
                        url: response.data.url,
                        sentToSharePoint: false,
                    }
                ]);
            });
        }

        reader.onerror = function (event) {
            console.error('Error reading file: ', event)
        }

        reader.readAsDataURL(file?.file);
    }

    const uploadProps = {
        multiple: allowMultiple,
        showUploadList: showUploadList,
        customRequest: customRequest || handleFileUpload,
    };

    return (
        <div id="requirementsDragger">

            <Dragger {...uploadProps}>

                <div>
                    <div><UploadIcon /></div>

                    <div className={styles["DraggerInfo-Header"]}>Drag and drop files here</div>

                    <div className={styles["DraggerInfo-SubHeader"]}>or</div>

                    <Button className={styles["browseButton"]}>Browse</Button>
                </div>

            </Dragger>
        </div>
    );
}