import CheckCurrency from "./CheckCurrency";
import EmailValidation from "./EmailValidation";
import FormatAmericanZipCode from "./FormatAmericanZipCode";
import FormatCanadianPostalCode from "./FormatCanadianPostalCode";
import FormatCurrency from "./FormatCurrency";
import FormatPhoneNumber from "./FormatPhoneNumber";
import FormatTelephone from "./FormatTelephone";
import RequiredField from "./RequiredField";
import ValidateAmount from "./ValidateAmount";
import ValidateConfirmPassword from "./ValidateConfirmPassword";
import ValidateCurrency from "./ValidateCurrency";
import ValidateDrivingExperience from "./ValidateDrivingExperience";
import ValidateKMS from "./ValidateKMs";
import ValidatePassword from "./ValidatePassword";
import ValidateRequiredField from "./ValidateRequiredField";
import ValidateSIN from "./ValidateSIN";
import ValidateSerialNumber from "./ValidateSerialNumber";
import ValidateVIN from "./ValidateVIN";
import ValidateYear from "./ValidateYear";
import WithinRange from "./WithinRange";

// TODO: This contains some old validations as well. Requires cleanup
const exportObj = {
    EmailValidation: EmailValidation,
    ValidateConfirmPassword: ValidateConfirmPassword,
    FormatPhoneNumber: FormatPhoneNumber,
    FormatTelephone: FormatTelephone,
    RequiredField: RequiredField,
    ValidateAmount: ValidateAmount,
    ValidateCurrency: ValidateCurrency,
    ValidateDrivingExperience: ValidateDrivingExperience,
    ValidateKMS: ValidateKMS,
    ValidatePassword: ValidatePassword,
    ValidateRequiredField: ValidateRequiredField,
    ValidateSerialNumber: ValidateSerialNumber,
    ValidateSIN: ValidateSIN,
    ValidateVIN: ValidateVIN,
    ValidateYear: ValidateYear,
    WithinRange: WithinRange,
    FormatCanadianPostalCode: FormatCanadianPostalCode,
    FormatAmericanZipCode: FormatAmericanZipCode,
    CheckCurrency: CheckCurrency,
    FormatCurrency: FormatCurrency,
}

export default exportObj;