import dayjs from 'dayjs';
import ApiService from '../../api';
import NotificationService from '../../services/notification.service';
import validate from '../../utils/Validations';
import utils from '../../utils';

const generateDraftData = (applicationDraftId, appDetailsData, pgData, coApps, assets, files, fileComments) => {
    const draftData = {
        ap_agentId: JSON.parse(localStorage?.user)?.agent_id,
        ap_clientId: appDetailsData?.client?.value,
        ap_assetDescription: utils.GenerateAssetDescription(assets) || 'Unspecified Asset',
        ap_applicationType: appDetailsData?.applicationType?.value,
        ap_requestedAmount: assets.reduce((total, asset) => total + Number(asset?.ap_price ?? 0), 0),
        ap_filedFSYearEnd: appDetailsData?.filedFSYearEnd?.value?.$d,
        ap_documents: {
            files: files,
        },
        ap_comments: fileComments,
        ap_corpCoApplicant: "None",
        ap_corporateCoApplicant1: null,
        ap_corporateCoApplicant2: null,
        ap_corporateCoApplicant3: null,
    };

    // Add Corporate Co-Applicants to the application
    const corpMapper = { 0: 'None', 1: 'One', 2: 'Two', 3: 'Three' }
    let corpCounter = 0;
    coApps.forEach(coApp => {
        if (coApp?.ap_clientId) {
            corpCounter++;
            draftData[`ap_corporateCoApplicant${corpCounter}`] = coApp?.ap_clientId?._id || coApp?.ap_clientId;
        }
    });
    draftData.ap_corpCoApplicant = corpMapper[corpCounter];

    return { draftData };
}

const handleClient = (value, setLoader, setAppDetailsData, id, appDetailsData, pgData, coApps, assets, files, fileComments, setUnsaved) => {
    const agentId = JSON.parse(localStorage?.user)?.agent_id;
    const clientId = value;
    setLoader({ loading: true, message: 'Verifying Client...' });
    ApiService.fetchRecentOpenApplications(agentId, clientId).then((openApps) => {
        ApiService.fetchClientOpenDrafts(clientId).then((openDrafts) => {
            if (!openApps || !openDrafts) {
                setLoader({ loading: false, message: '' });
                return;
            }

            const openExists = openApps?.length > 0 || openDrafts?.length > 0;
            const notifMessage = (openApps?.length > 0) ? 'Client already has an open application.' : (openDrafts?.length > 0) ? 'Client already has a drafted application.' : '';
            const errorMessage = (openApps?.length > 0) ? 'This client already has an open application.' : (openDrafts?.length > 0) ? 'This client already has a drafted application.' : '';

            setAppDetailsData((prevAppDetailsData) => ({
                ...prevAppDetailsData,
                client: {
                    ...prevAppDetailsData.client,
                    value: openExists ? '' : clientId,
                    error: openExists ? errorMessage : validate.RequiredField(clientId),
                }
            }));
            if (setUnsaved) setUnsaved(true);
            const updatedAppDetailsData = {
                ...appDetailsData,
                client: {
                    ...appDetailsData.client,
                    value: openExists ? '' : clientId,
                    error: openExists ? errorMessage : validate.RequiredField(clientId),
                }
            }

            if (openExists)
                NotificationService.error(notifMessage);
            else {
                const { draftData } = generateDraftData(id, updatedAppDetailsData, pgData, coApps, assets, files, fileComments);
                if (!id) {
                    // Create Application Draft
                    setLoader({ loading: true, message: 'Saving Application Draft...' });
                    ApiService.createApplicationDraft(draftData).then((response) => {
                        setLoader({ loading: false, message: '' });

                        if (response?.error)
                            NotificationService.error("Unfortunately, Application Draft cannot be Saved.")
                        else {
                            const applicationDraftId = response?.success?.applicationDraftId
                            if (applicationDraftId) {
                                // Create PGs
                                pgData.forEach(pg => {
                                    const individualPg = { ...pg, ap_applicationDraftId: applicationDraftId }
                                    ApiService.createPG(individualPg);
                                });

                                // Create Assets
                                assets.forEach(asset => {
                                    const individualAsset = { ...asset, ap_applicationDraftId: applicationDraftId }
                                    ApiService.createAsset(individualAsset);
                                });
                                // Redirect the user to that Application Draft
                                window.location.href = `/applications/draft/${applicationDraftId}`
                            }
                        }
                    });
                }
            }

            setLoader({ loading: false, message: '' });
        });
    });
}

const handleApplicationType = (value, setAppDetailsData, setUnsaved) => {
    setAppDetailsData((prevAppDetailsData) => ({
        ...prevAppDetailsData,
        applicationType: {
            ...prevAppDetailsData.applicationType,
            value: value,
        },
        filedFSYearEnd: {
            ...prevAppDetailsData.filedFSYearEnd,
            required: value === 'Application with Financials',
            error: ''
        }
    }));
    if (setUnsaved) setUnsaved(true);
}

const handleFiledFSYearEnd = (dateString, setAppDetailsData, setUnsaved) => {
    setAppDetailsData(prevAppDetailsData => ({
        ...prevAppDetailsData,
        filedFSYearEnd: {
            ...prevAppDetailsData.filedFSYearEnd,
            value: dateString ? dayjs(dateString, "DD-MM-YYYY") : '',
            error: prevAppDetailsData?.applicationType?.value === 'Application with Financials' ? validate.RequiredField(dateString) : ''
        }
    }));
    if (setUnsaved) setUnsaved(true);
}

const autoSaveApplication = (
    id,
    appDetailsData,
    pgData,
    coApps,
    assets,
    files,
    fileComments,
    setRefresh,
    setRefreshPgs,
    setRefreshAssets,
    setUnsaved
) => {
    const { draftData } = generateDraftData(id, appDetailsData, pgData, coApps, assets, files, fileComments);

    // Array to store promises for all API Calls
    const apiPromises = [];
    let pgrefresh = false;
    let assetrefresh = false;

    ApiService.updateApplicationDraft(id, draftData).then(response => {
        if (response?.success) {
            // PGs
            pgData.forEach(pg => {
                const individualPg = {
                    ...pg,
                    ap_applicationDraftId: id,
                }

                // If the Individual PG has an ID, then update it
                if (individualPg?._id) {
                    apiPromises.push(
                        ApiService.updatePGDraft(individualPg?._id, individualPg)
                            .then(response => {
                                if (!response?.error)
                                    pgrefresh = true;
                            })
                    );
                } else {
                    if (individualPg?.ap_contactId)
                        apiPromises.push(
                            ApiService.createPGDrafts(individualPg)
                                .then(response => {
                                    if (!response?.error)
                                        pgrefresh = true;
                                })
                        );
                }
            });

            // Create or Update Assets
            assets.forEach(asset => {
                const individualAsset = {
                    ...asset,
                    ap_applicationDraftId: id,
                    ap_price: String(asset?.ap_price),
                };

                // Check the delete once and delete the Asset wherever needed

                // If the Individual Asset has an ID, then update it
                if (individualAsset?._id)
                    apiPromises.push(
                        ApiService.updateAsset(individualAsset?._id, individualAsset)
                            .then(response => {
                                if (response?.success)
                                    assetrefresh = true;
                            })
                    )
                else
                    apiPromises.push(
                        ApiService.createAsset(individualAsset)
                            .then(response => {
                                if (response?.success)
                                    assetrefresh = true;
                            })
                    );
            });

            Promise.all(apiPromises)
                .then(() => {
                    if (pgrefresh)
                        setRefreshPgs(prevRefreshPgs => prevRefreshPgs + 1);

                    if (assetrefresh)
                        setRefreshAssets(prevRefreshAssets => prevRefreshAssets + 1);

                    setRefresh(prevRefresh => prevRefresh + 1);
                    if (setUnsaved) setUnsaved(false);
                    pgrefresh = false;
                    assetrefresh = false;
                })
                .catch(error => console.log("Error in API Calls: ", error))

        } else console.log(response?.error);
    })
}

const handleSendApplication = (
    setValidatePg,
    setLoader,
    appDetailsData,
    setAppDetailsData,
    assets,
    setShowAssetError,
    files,
    setShowDocumentsError,
    pgData,
    coApps,
    setOpenSendModal,
    id,
    fileComments,
    setRefresh,
    setRefreshPgs,
    setRefreshAssets,
    setAutoSave
) => {

    autoSaveApplication(id, appDetailsData, pgData, coApps, assets, files, fileComments, setRefresh, setRefreshPgs, setRefreshAssets);

    setValidatePg(prevValidatePg => prevValidatePg + 1);
    setLoader({ loading: true, message: 'Validating Application Details...' });
    const isAppOnly = appDetailsData?.applicationType?.value === 'Application Only';

    const tempAppDetailsData = {
        ...appDetailsData,
        client: {
            ...appDetailsData?.client,
            error: validate.RequiredField(appDetailsData?.client?.value)
        },
        applicationType: {
            ...appDetailsData?.applicationType,
            error: validate.RequiredField(appDetailsData?.applicationType?.value)
        },
        filedFSYearEnd: {
            ...appDetailsData?.filedFSYearEnd,
            error: isAppOnly ? '' : validate.RequiredField(appDetailsData?.filedFSYearEnd?.value)
        }
    };
    setAppDetailsData(tempAppDetailsData);

    const hasAppDetailsError = Object.values(tempAppDetailsData).some(item => item?.error);

    setLoader({ loading: true, message: 'Validating Assets...' });
    const hasAssetError = assets?.length === 0;
    setShowAssetError(hasAssetError);

    setLoader({ loading: true, message: 'Validating Documents...' });
    const hasDocuments = files?.length > 0;
    setShowDocumentsError(!hasDocuments);

    setLoader({ loading: false, message: '' });

    const pgError = pgData.some(data => {
        const birthDate = ((typeof (data?.ap_birthDate) === 'string') ? data?.ap_birthDate : (data?.ap_birthDate)?.toISOString()) || '';
        const hasContactIdError = validate.RequiredField(data?.ap_contactId) !== "";
        const hasBirthDateError = validate.RequiredField(birthDate) !== "";
        const hasDrivingExpError = data?.ap_drivingExperienceYears ? validate.ValidateDrivingExperience(data?.ap_drivingExperienceYears).error !== "" : '';
        const hasSinError = validate.ValidateSIN(data?.ap_sin).error !== "";

        return hasContactIdError || hasBirthDateError || hasDrivingExpError || hasSinError;
    });

    const coAppError = coApps.some(data => {
        return validate.RequiredField(data?.ap_clientId) !== "";
    })

    if (!hasAppDetailsError && !hasAssetError && hasDocuments && !pgError && !coAppError) {
        setAutoSave(false);
        setOpenSendModal(true);
    } else {
        setAutoSave(true);
        setOpenSendModal(false);
        const scrollContainer = hasAppDetailsError ? "AppDetailsContainer" : (pgError || coAppError) ? "PgCoAppContainer" : hasAssetError ? "AssetContainer" : !hasDocuments ? "NewDocumentsContainer" : "";

        const elementToScrollTo = document.getElementById(scrollContainer);
        if (elementToScrollTo) {
            elementToScrollTo.style.scrollMarginTop = '20px';
            elementToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
}

const handleSubmit = (id, setLoader, appDetailsData, assets, pgData, coApps, files, fileComments, documentsList, setRefresh, setOpenSendModal, setAutoSave) => {
    setLoader({ loading: true, message: 'Submitting Application...' });
    const data = {
        ap_agentId: JSON.parse(localStorage?.user)?.agent_id,
        ap_clientId: appDetailsData?.client?.value,
        ap_assetDescription: utils.GenerateAssetDescription(assets),
        ap_applicationType: appDetailsData?.applicationType?.value,
        ap_filedFSYearEnd: appDetailsData?.filedFSYearEnd?.value?.$d,
        ap_requestedAmount: utils.convertToDBCurrency(assets.reduce((total, asset) => total + Number(asset?.ap_price || 0), 0)),
        ap_documents: {
            files: files,
            uploadedDocuments: Object.values(documentsList).filter(doc => doc?.checked).map(doc => doc?.name)
        },
        ap_comments: fileComments,
        ap_applicationDraftId: id
    }

    // Add Corporate Co-Applicants to the application
    const corpMapper = { 0: 'None', 1: 'One', 2: 'Two', 3: 'Three' }
    let corpCounter = 0;
    coApps.forEach(coApp => {
        if (coApp?.ap_clientId) {
            corpCounter++;
            data[`ap_corporateCoApplicant${corpCounter}`] = coApp?.ap_clientId;
        }
    });
    data.ap_corpCoApplicant = corpMapper[corpCounter];

    ApiService.createApplication(data).then(applicationResponse => {
        if (!applicationResponse)
            return;

        const applicationId = applicationResponse?.success?.applicationId;
        if (applicationId) {
            // Assign Assets to the application
            assets.forEach(asset => {
                const updateAssetData = {
                    ...asset,
                    ap_applicationId: applicationId,
                    ap_vendorId: asset?.ap_vendorId?._id || asset?.ap_vendorId,
                    ap_price: String(asset?.ap_price)
                }
                delete updateAssetData?._id;
                ApiService.updateAsset(asset?._id, updateAssetData).then(assetResponse => {
                    if (!assetResponse)
                        console.error("Error creating asset with data: ", asset)
                });
            });

            // PG creation API
            pgData.forEach(pg => {
                const newPgData = {
                    ...pg,
                    ap_applicationId: applicationId,
                    ap_new: true,
                }
                delete newPgData?._id;

                ApiService.createPG(newPgData).then(pgResponse => {
                    if (!pgResponse)
                        console.error("Error creating PG with data: ", pg);
                });
            });

            // Move the documents from Draft folder to application folder
            const blobData = {
                containerName: JSON.parse(localStorage?.user)?.agent_id,
                sourceFolderName: `Applications/Drafts/${id}/`,
                destinationFolderName: `Applications/${applicationId}/`,
            }

            ApiService.moveDocuments(blobData).then(documentsResponse => {
                if (!documentsResponse) return;
                if (documentsResponse?.code === 200) {
                    const updatedFileData = {
                        ap_documents: {
                            ...(data?.ap_documents || {}),
                            files: files.map(file => ({
                                ...file,
                                url: utils.UpdateFileUrl(file?.url, `Drafts/${id}`, applicationId)
                            }))
                        }
                    };

                    ApiService.updateApplication(applicationId, updatedFileData).then(response => {
                        if (!response) return;
                        if (response?.success) {

                            const updateDraftData = {
                                ap_applicationId: applicationId,
                                ap_status: 'Inactive'
                            }
                            ApiService.updateApplicationDraft(id, updateDraftData).then(response => {
                                if (!response) return;
                                setRefresh(prevRefresh => prevRefresh + 1);
                                window.location.href = `/applications/${applicationId}`;
                            });
                        }
                    });
                }
            });
            setAutoSave(false)
            setOpenSendModal(false);
            setLoader({ loading: false, message: '' });
        } else setLoader({ loading: false, message: '' });
    });
}

const AppUtils = {
    handleClient,
    handleApplicationType,
    handleFiledFSYearEnd,
    autoSaveApplication,
    handleSendApplication,
    handleSubmit,
}

export default AppUtils;